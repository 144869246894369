import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import { PrivateFunderDetails, PublicFunderDetails } from '../connectors/Pages/Details/Funder/interfaces.ts';
import { TContactPerson } from '../connectors/Pages/Profile/Account/components/ContactPerson';
import { TSearchUser } from '../connectors/Pages/Profile/Roles/components/SearchEmailAddress';
import { TRoles } from '@fi/constants';
import { User } from '../context/AuthContext/types.ts';

import reducers from './reducers.js';
import allSagas from './sagas.js';
import { Source } from '../opportunities/opportunity.types.ts';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

let enhancerComposer;
if (IS_PRODUCTION) {
  enhancerComposer = compose;
} else {
  enhancerComposer = composeWithDevTools;
}

const enhancers = [applyMiddleware(...middlewares)];

// @ts-ignore
export const AppStore = createStore(reducers, enhancerComposer(...enhancers));

sagaMiddleware.run(allSagas);

export type RootState = {
  user: {
    isLoadingUserData: boolean;
    isSavingUserPreferences: boolean;
    isLoadingUserPreferences: boolean;
    userData: User;
    userPreferences: TUserPreferences;
  };
  awards: {
    awardedInstitutions: { items: TRemappedAwardedInstitution[]; cache: TRemappedAwardedInstitution[] };
    isLoadingResults: boolean;
  };
  usersRoles: {
    accountContactPerson: TContactPerson | null;
    searchResults: TSearchUser;
    searchError: boolean;
    isLoadingSearchResults: boolean;
    notFound: boolean;
    changeRole: TQueryState<null>;
    isLoadingUsers: boolean;
    roles: {
      allUsersIds: string[];
      byUserId: { [key: string]: TUserWithRole };
    };
    modal: {
      modalType: string;
      modalProps: {
        userId: string;
      };
    };
    redirectAfterUpdate: boolean;
    changeRoleStatus: {
      type: string;
      success: boolean;
    };
    isUpdatingContactPerson: boolean;
    updatingUsersIds: string[];
  };
  funders: {
    publicFunderDetails: PublicFunderDetails;
    privateFunderDetails: PrivateFunderDetails;
    isLoadingPublicFunderDetails: boolean;
    isLoadingPrivateFunderDetails: boolean;
    isLoadingResults: boolean;
  };
  opportunities: {
    searchHistory: string;
    searchQuery: TOpportunitySearchFilters;
    apiQuery: TApiQuery;
    fundingTypes: { items: TOpportunitySearchFilter[] | [] };
    results: Source[];
    selectedItems: { [key: string]: number };
    totalResults: number;
    isLoadingResults: boolean;
    isLoading: false;
    isMetadataFetched: boolean;
    fundingOrganizations: TFundingOrganizations;
    opportunityDetails: object;
    opportunityNotes: object;
    funderType: { items: TFunderItem[] };
    funderTypes: Record<string, never>; // accidental duplicate?
    savedSearchOptions: object;
    selectAllContext: string;
    exportFailed: null | boolean;
    individualApplicantTypes: { items: TOpportunitySearchFilter[] };
    organizationalApplicantTypes: { items: TOpportunitySearchFilter[] };
    degreeRequirements: { items: TOpportunitySearchFilter[] };
    researchAreas: TResearchAreas;
    restrictions: {
      items: TOpportunitySearchFilter[];
    };
  };
  fixtures: {
    countries: { items: TCountryItem[] };
  };
};

export type TUserPreferences = {
  id: number;
  institutionId: number;
  userId: string;
} | null;

/**
 * Response for endpoint **\/api/autocompletion/awardedInstitutions?id=709700**
 */
export type TAwardedInstitution = {
  text: string;
  id: string;
  count?: any;
};

/**
 * **ATTENTION* this is not the actual API response
 * The original response is remapped in an action
 * */
export type TRemappedAwardedInstitution = {
  key: string;
  value: string;
};

/**
 * This type follows the query state structure of Tanstack Query
 * Why? To not reinvent the wheel. We could also base this on RTK or whatever.
 */
type TQueryState<T> = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: {
    message: string | undefined;
  };
  data: T | undefined;
};

export type TUserWithRole = {
  accountId: string;
  id: number;
  role: TRoles;
  user: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phone: string;
  };
};

/**
 * Describes all opportunity search filters.
 * The searchQuery for opportunities extends this type.
 */
export type TOpportunitySearchFilters = {
  keyword: string;
  currentPage: number;
  sortOptions: TSortOption[];
  selectedSortOption: TSortOption;
  researchAreas: TResearchAreasFilter;
  fundingTypes: {
    selectedItems: TOpportunitySearchFilter[] | [];
  };
  countries: {
    selectedItems: TCountryItem[] | [];
  };
  funderCountries: {
    selectedItems: TCountryItem[] | [];
  };
  funderType: {
    selectedItems: TFunderItem[] | [];
  };
  amount: TAmount;
  limitedSubmission: boolean;
  onlyInternal: boolean;
  lastUpdateDate: null | string;
  includeExpired: boolean;
  forthcoming: boolean;
  applicationDeadline: { selectedItems: [] } | TApplicationDeadline;
  fundingOrganizations: {
    selectedItems: TFundingOrganization[] | [];
  };
  applicantCountry: {
    selectedItems: TCountyItem[] | [];
  };
  activityCountries: {
    selectedItems: TCountyItem[] | [];
  };
  advanceSearch: TAdvancedSearch;
  individualApplicantTypes: {
    selectedItems: TOpportunitySearchFilter[];
  };
  organizationalApplicantTypes: {
    selectedItems: TOpportunitySearchFilter[];
  };
  degreeRequirements: {
    selectedItems: TOpportunitySearchFilter[];
  };
  restrictions: {
    selectedItems: TOpportunitySearchFilter[];
  };
  onlyRecurring: boolean;
  xfn: boolean;
  xfnCn: boolean;
  xApCn: boolean;
  xApCz: boolean;
  xOlCn: boolean;
  savedSearchName: string;
  unspecCit: boolean;
  unspecCountry: boolean;
};

type TSortOption = {
  label: string;
  value: string;
};

type TCountryItem = {
  key: string;
  value: string;
  keywords: string[];
  flag: string;
};

export type TAmount = {
  min: number;
  max: number;
  includeUnspecifiedAmounts: boolean;
};

type TApplicationDeadline = {
  selectedItems: TApplicationDeadlineRange[];
  startDate: string | null; // YYYY-MM-DD
  endDate: string | null; // YYYY-MM-DD
  renderUnspecified: boolean;
  datePickerSelected: boolean;
  checkBoxSelected: boolean;
};

type TApplicationDeadlineRange =
  | {
      key: '14-30';
      value: 'In 14 to 30 days';
      min: 14;
      max: 30;
    }
  | {
      key: '30-90';
      value: 'In 30 to 90 days';
      min: 30;
      max: 90;
    }
  | {
      key: '90-';
      value: 'More than 90 days';
      min: 90;
      max: -1;
    };

type TFundingOrganization = {
  key: string;
  value: string;
  displayValue: string;
  count?: string;
};

export type TFundingOrganizations = {
  items: TFundingOrganization[];
  countMap: { [key: string]: string };
  itemsMap: {
    [key: string]: TFundingOrganization;
  };
};

type TCountyItem = {
  key: string;
  value: string;
  keywords: string[];
  flag: string;
};

type TAdvancedSearch = {
  all: TAdvancedSearchElement;
  any: TAdvancedSearchElement;
  optional: TAdvancedSearchElement;
  none: TAdvancedSearchElement;
};

type TAdvancedSearchElement = {
  value: string;
  command: string;
};

type TOpportunitySearchFilter = {
  id: string;
  key: string;
  value: string;
  count: number;
  description: string;
};

type TFunderItem = {
  id: string;
  key: string;
  value: string;
  count: number;
  types: number;
};

export type TResearchArea = {
  codeSystem?: 'ASJC';
  code: string;
  description: string;
  disciplineOrder?: number;
  count?: string;
  id?: number;
  parentId?: number;
  groupId?: number;
};

export type TResearchAreaItem = {
  parent: TResearchArea;
  children: TResearchArea[];
  topLevelDescription: string;
  originalChildren: TResearchArea[];
};

export type TResearchAreas = {
  items: TResearchAreaItem[];
  countMap: {
    [key: string]: string;
  };
  initialCountMap: {
    [key: string]: string;
  };
};

export type TResearchAreasFilter = {
  parentMap:
    | Record<string, never> // initialized as empty object
    | {
        [key: string]: { parent: TResearchArea; children: TResearchArea[] };
      };
  childMap:
    | Record<string, never> // initialized as empty object
    | {
        [key: string]: TResearchArea;
      };
};

type TApiQuery = {
  pageSize: number;
  page: number;
  orderBy: string[];
  status: string[];
  applicantCountry: string[];
  funderTypeGroupId: number[];
  oat: string[];
  deadlineDaysMinimum: number[];
  limitedSubmission: boolean;
  forthcoming: boolean;
};
