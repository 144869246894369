import { applicationDeadlines } from '@fi/libs/fros-ui/facets/applicationDeadline';

import {
  SEARCH_CATEGORIES,
  PAGE_SIZE,
  OPPORTUNITIES_SORT_OPTIONS,
  FUNDERS_SORT_OPTIONS,
  AWARDS_SORT_OPTIONS,
  ADVANCE_SEARCH_COMMAND,
  PARAM_NAMES_MAP,
} from '@fi/constants';

import { formatStringToSnakeCase } from './formatStringToSnakeCase.ts';
import { getUrlParameters } from './getUrlParameters.js';
import { NumberUtils } from './NumberUtils.js';
import {
  createAdvancedSearchQueryObject,
  createAmountQueryObject,
  createDisciplinesQueryObject,
  createListQueryObject,
} from '../query/commonSearchQuery.ts';
import {
  createApplicationDeadlinesQueryObject,
  createLastUpdatedQueryObject,
  createStatusQueryObject,
} from '../query/opportunitySearchQuery.ts';
import { createFunderTypesQueryObject } from '../query/funderSearchQuery.ts';
import { formatApiDate } from './formatDate.ts';

/**
 * QueryManager:
 * - Manages search state
 * - Translates URL Search parameter names to the names we use for API calls
 *
 * Example: `sub-disciplines` (URL) --> `asjcCategories` (API)
 *
 * Major improvements for QueryManager would be:
 * - introducing `TypeScript`
 * - names of Search Parameters in the URL match the names of parameters on our API side.
 */
export class QueryManager {
  static createApiQueryFromSelectedItems(searchQuery, itemKey, paramName, keyName = 'key', valueToSnakeCase = false, encodeString = false) {
    const queryObject = createListQueryObject(itemKey, paramName, keyName, valueToSnakeCase, encodeString)(searchQuery);

    if (!queryObject[paramName]) {
      return '';
    }

    return queryObject[paramName].map((value) => `${paramName}=${value}`).join('&');
  }

  static createUrlQueryFromSelectedItems(items, paramName, keyName = 'key', valueToSnakeCase = false, encodeValue = false) {
    let queries = [];
    let result = '';
    if (items) {
      queries = items.selectedItems.map((item) => {
        let itemValue = item[keyName];
        if (valueToSnakeCase) {
          itemValue = formatStringToSnakeCase(item[keyName]);
        } else if (encodeValue) {
          itemValue = encodeURIComponent(item[keyName]);
        }
        return itemValue;
      });
      const joinedQuery = queries.join(',');
      if (joinedQuery) {
        result = `${paramName}=${joinedQuery}`;
      }
    }
    return result;
  }

  static createFunderTypeApiQuery(searchQuery) {
    const queryObject = createFunderTypesQueryObject(searchQuery);

    if (!queryObject.funderTypeGroupId) {
      return '';
    }

    return queryObject.funderTypeGroupId.map((id) => `funderTypeGroupId=${id}`).join('&');
  }

  static createStatusQuery(searchQuery, options) {
    const queryObject = createStatusQueryObject(searchQuery, options);

    if (!queryObject.status) {
      return '';
    }

    return `status=${queryObject.status}`;
  }

  // create advance search query
  static createAdvanceSearchQuery(searchQuery) {
    const queryObject = createAdvancedSearchQueryObject(searchQuery);

    return Object.keys(queryObject)
      .map((key) => `${key}=${queryObject[key]}`)
      .join('&');
  }

  static getSortSelection(selectedSortOption) {
    return selectedSortOption ? `orderBy=${selectedSortOption.value}` : '';
  }

  static createDisciplinesQuery(searchQuery, options) {
    const queryObject = createDisciplinesQueryObject(searchQuery, options);

    if (!queryObject.asjcCategories) {
      return '';
    }

    return queryObject.asjcCategories.map((code) => `asjcCategories=${code}`).join('&');
  }

  static createApplicationDeadlinesQuery(searchQuery) {
    const queryObject = createApplicationDeadlinesQueryObject(searchQuery);

    return Object.keys(queryObject)
      .map((key) => {
        if (Array.isArray(queryObject[key])) {
          return queryObject[key].map((value) => `${key}=${value}`).join('&');
        }
        return `${key}=${queryObject[key]}`;
      })
      .join('&');
  }

  static createAmountQuery(searchQuery) {
    const queryObject = createAmountQueryObject(searchQuery);

    return Object.keys(queryObject)
      .map((key) => `${key}=${queryObject[key]}`)
      .join('&');
  }

  static createBooleanQuery(searchQuery, propName, paramName) {
    return searchQuery[propName] ? `${paramName}=true` : '';
  }

  static createStringQuery(searchQuery, paramName) {
    return searchQuery[paramName] ? `${paramName}=${searchQuery[paramName]}` : '';
  }

  static createLastUpdatedQuery(searchQuery, options) {
    const queryObject = createLastUpdatedQueryObject(searchQuery, options);

    return Object.keys(queryObject)
      .map((key) => `${key}=${queryObject[key]}`)
      .join('&');
  }

  static createDisciplinesUrlQuery(selectedItems) {
    if (selectedItems) {
      const { parentMap } = selectedItems;
      const disciplines = [];
      Object.keys(parentMap).forEach((key) => {
        if (parentMap[key]) {
          disciplines.push(formatStringToSnakeCase(parentMap[key].parent.description));
        }
      });
      if (disciplines.length) {
        return `discipline=${disciplines.join(',')}`;
      }
    }
    return '';
  }

  static createSubDisciplinesUrlQuery(selectedItems) {
    if (selectedItems) {
      const { childMap } = selectedItems;
      const subDisciplines = [];
      Object.keys(childMap).forEach((key) => {
        if (childMap[key]) {
          subDisciplines.push(childMap[key].code);
        }
      });
      if (subDisciplines.length) {
        return `sub-discipline=${subDisciplines.join(',')}`;
      }
    }
    return '';
  }

  static createAmountUrlQuery(amount) {
    if (amount) {
      const queries = [];
      if (amount.min) {
        queries.push(`amountMin=${amount.min}`);
      }
      if (amount.max) {
        queries.push(`amountMax=${amount.max}`);
      }
      return queries.join('&');
    }
    return '';
  }

  static createDueDatesQuery(dueDates) {
    if (dueDates) {
      const queries = [];
      const deadlineRange = QueryManager.createUrlQueryFromSelectedItems(dueDates, 'deadline', 'value', true);
      if (deadlineRange) {
        queries.push(deadlineRange);
      }
      if (dueDates.startDate) {
        queries.push(`minApplicationDueDate=${dueDates.startDate}`);
      }
      if (dueDates.endDate) {
        queries.push(`maxApplicationDueDate=${dueDates.endDate}`);
      }
      return queries.join('&');
    }
    return '';
  }

  /* ------------------------------------------------------- */
  /* -------- Query Generators for URL to state ----------- */
  /* ------------------------------------------------------- */

  // We don't need this function at all in future and we need to clean everything related to old search
  static createAdvanceSearchQueryState() {
    const urlParams = new URLSearchParams(window.location.search);
    const advanceSearch = {
      all: { value: '', command: ADVANCE_SEARCH_COMMAND.ALL },
      any: { value: '', command: ADVANCE_SEARCH_COMMAND.ANY },
      optional: { value: '', command: ADVANCE_SEARCH_COMMAND.OPTIONAL },
      none: { value: '', command: ADVANCE_SEARCH_COMMAND.NONE },
    };
    urlParams.forEach((val, key) => {
      if (key === 'anyQuery' || key === 'allQuery' || key === 'noneQuery' || key === 'optionalQuery') {
        const command = key.replace(/Query/, '');
        advanceSearch[command].val = val.trim();
      }
    });

    return { advanceSearch };
  }

  static createResearchAreasState(disciplinesQuery, subDisciplinesQuery, prevDisciplines) {
    const researchAreas = {
      parentMap: {},
      childMap: {},
    };
    const selectedFirstLevelItems = disciplinesQuery ? disciplinesQuery.split(',') : [];
    const selectedSecondLevelItems = subDisciplinesQuery ? subDisciplinesQuery.split(',') : [];
    prevDisciplines.forEach((firstLevel) => {
      const query = formatStringToSnakeCase(firstLevel.parent.description);
      if (selectedFirstLevelItems.indexOf(query) > -1) {
        researchAreas.parentMap[firstLevel.parent.code] = firstLevel;
      }
      firstLevel.children.forEach((secondLevel) => {
        if (selectedSecondLevelItems.indexOf(secondLevel.code) > -1) {
          researchAreas.childMap[secondLevel.code] = secondLevel;
        }
      });
    });
    return {
      researchAreas,
    };
  }

  static createSelectedItemState(
    allValues,
    urlQuery,
    options = {
      stateKey: null,
      valueKey: 'key',
      allValuesKey: 'items',
      formatToSnakeCase: false,
      encodeValue: false,
    },
  ) {
    const state = {
      selectedItems: [],
    };
    const allValuesKey = options.allValuesKey || 'items';
    const valueKey = options.valueKey || 'key';
    const formatToSnakeCase = typeof options.formatToSnakeCase !== 'undefined' ? options.formatToSnakeCase : false;
    const encodeValue = typeof options.encodeValue !== 'undefined' ? options.encodeValue : false;
    if (urlQuery) {
      const selected = urlQuery.split(',');
      allValues[allValuesKey].forEach((item) => {
        let itemKey = item[valueKey];
        if (formatToSnakeCase) {
          itemKey = formatStringToSnakeCase(itemKey);
        } else if (encodeValue) {
          itemKey = encodeURIComponent(itemKey);
        }
        if (selected.includes(itemKey)) {
          state.selectedItems.push(item);
        }
      });
    }
    return {
      [options.stateKey]: state,
    };
  }

  static createSelectedAmountState(amountMin, amountMax) {
    const amount = {
      min: 0,
      max: 0,
      includeUnspecifiedAmounts: false,
    };
    if (amountMin) {
      Object.assign(amount, {
        min: NumberUtils.commaSeparatedStringToNumber(amountMin),
      });
    }
    if (amountMax) {
      Object.assign(amount, {
        max: NumberUtils.commaSeparatedStringToNumber(amountMax),
      });
    }
    return {
      amount,
    };
  }

  static createDueDatesState(deadlineQuery, minApplicationDueDateQuery, maxApplicationDueDateQuery) {
    const applicationDeadline = {
      selectedItems: [],
      startDate: null,
      endDate: null,
      renderUnspecified: false,
    };
    if (deadlineQuery) {
      const selectedItems = deadlineQuery.split(',');
      applicationDeadline.selectedItems = selectedItems
        .map((value) => applicationDeadlines.find((item) => formatStringToSnakeCase(item.value) === value))
        .filter((item) => item);
      applicationDeadline.datePickerSelected = false;
      applicationDeadline.checkBoxSelected = true;
    } else if (minApplicationDueDateQuery || maxApplicationDueDateQuery) {
      applicationDeadline.datePickerSelected = true;
      applicationDeadline.checkBoxSelected = false;
      if (minApplicationDueDateQuery) {
        applicationDeadline.startDate = formatApiDate(minApplicationDueDateQuery);
      }
      if (maxApplicationDueDateQuery) {
        applicationDeadline.endDate = formatApiDate(maxApplicationDueDateQuery);
      }
    }
    return {
      applicationDeadline,
    };
  }

  static generateApiQuery(searchQuery, options) {
    let queries = [
      `?pageSize=${PAGE_SIZE}`,
      `page=${searchQuery.currentPage - 1}`,
      QueryManager.getSortSelection(searchQuery.selectedSortOption),
    ];

    if (options.scope === SEARCH_CATEGORIES.OPPORTUNITIES) {
      queries = queries.concat([
        QueryManager.createStatusQuery(searchQuery, options),
        QueryManager.createAdvanceSearchQuery(searchQuery),
        QueryManager.createDisciplinesQuery(searchQuery, options),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'fundingOrganizations', 'subSponsor'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'countries', 'citizenship'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'applicantCountry', 'applicantCountry'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'funderCountries', 'funderCountry'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'funderType', 'funderTypeGroupId', 'types'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'individualApplicantTypes', 'iat'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'organizationalApplicantTypes', 'oat'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'degreeRequirements', 'dr'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'restrictions', 'rst'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'activityCountries', 'ol'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'fundingTypes', 'types'),
        QueryManager.createApplicationDeadlinesQuery(searchQuery),
        QueryManager.createAmountQuery(searchQuery),
        QueryManager.createBooleanQuery(searchQuery, 'onlyRecurring', 'onlyRecurring'),
        QueryManager.createBooleanQuery(searchQuery, 'limitedSubmission', 'limitedSubmission'),
        QueryManager.createStringQuery(searchQuery, 'lastUpdateDate'),
        QueryManager.createBooleanQuery(searchQuery, 'xfn', 'xfn'),
        QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
        QueryManager.createBooleanQuery(searchQuery, 'xApCn', 'xApCn'),
        QueryManager.createBooleanQuery(searchQuery, 'xApCz', 'xApCz'),
        QueryManager.createBooleanQuery(searchQuery, 'xOlCn', 'xOlCn'),
        QueryManager.createBooleanQuery(searchQuery, 'onlyInternal', 'onlyInternal'),
        QueryManager.createBooleanQuery(searchQuery, 'includeExpired', 'expired'),
        QueryManager.createBooleanQuery(searchQuery, 'forthcoming', 'forthcoming'),
        QueryManager.createBooleanQuery(searchQuery, 'unspecCit', 'unspecCit'),
        QueryManager.createBooleanQuery(searchQuery, 'unspecCountry', 'unspecCountry'),
        QueryManager.createLastUpdatedQuery(searchQuery, options),
      ]);
    } else if (options.scope === SEARCH_CATEGORIES.FUNDERS) {
      queries = queries.concat([
        QueryManager.createAdvanceSearchQuery(searchQuery),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'funderCountries', 'countryCodes'),
        QueryManager.createFunderTypeApiQuery(searchQuery),
        QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
      ]);
    } else if (options.scope === SEARCH_CATEGORIES.AWARDS) {
      queries = queries.concat([
        QueryManager.createAdvanceSearchQuery(searchQuery),
        QueryManager.createDisciplinesQuery(searchQuery, options),
        QueryManager.createAmountQuery(searchQuery),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'fundingOrganizations', 'subSponsor'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'funderType', 'funderTypeGroupId', 'types'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'startYear', 'years', 'value'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'awardeeCountries', 'countryCodes'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'grantTypes', 'type'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'awardedInstitutions', 'awardedInstitutionId'),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'awardedAuthors', 'awardedAuthorName', 'key', false, true),
        QueryManager.createApiQueryFromSelectedItems(searchQuery, 'funderCountries', 'funderCountry'),
        QueryManager.createBooleanQuery(searchQuery, 'xRcCn', 'xRcCn'),
        QueryManager.createBooleanQuery(searchQuery, 'xfn', 'xfn'),
        QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
      ]);
    }
    return queries.filter((item) => item).join('&');
  }

  static generateUrlQueryWithQueryType(searchQuery, options) {
    let newUrl = QueryManager.generateUrlQuery(searchQuery, options);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('queryType')) {
      newUrl = `${newUrl}&queryType=${urlParams.get('queryType')}`;
    }
    return newUrl;
  }

  static generateUrlQuery(searchQuery, options) {
    let queries = [
      `?page=${searchQuery.currentPage}`,
      QueryManager.getSortSelection(searchQuery.selectedSortOption),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.funderType, 'funderType', 'value', true),
    ];
    if (options.scope === SEARCH_CATEGORIES.OPPORTUNITIES) {
      queries = QueryManager.generateOpportunitiesUrlQuery(searchQuery, queries);
    } else if (options.scope === SEARCH_CATEGORIES.FUNDERS) {
      queries = QueryManager.generateFundersUrlQuery(searchQuery, queries);
    } else if (options.scope === SEARCH_CATEGORIES.AWARDS) {
      queries = QueryManager.generateAwardsUrlQuery(searchQuery, queries);
    }
    return queries.filter((item) => item).join('&');
  }

  static generateOpportunitiesUrlQuery(searchQuery, queries) {
    return queries.concat([
      QueryManager.createAdvanceSearchQuery(searchQuery),
      QueryManager.createDisciplinesUrlQuery(searchQuery.researchAreas),
      QueryManager.createSubDisciplinesUrlQuery(searchQuery.researchAreas),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.fundingTypes, 'type'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.countries, 'citizenship'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.applicantCountry, 'applicantCountry'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.activityCountries, PARAM_NAMES_MAP.activityCountries),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.fundingOrganizations, 'funder'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.funderCountries, 'funderCountries'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.individualApplicantTypes, 'iat'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.organizationalApplicantTypes, 'oat'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.degreeRequirements, 'dr'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.restrictions, 'rst'),
      QueryManager.createAmountUrlQuery(searchQuery.amount),
      QueryManager.createDueDatesQuery(searchQuery.applicationDeadline),
      QueryManager.createBooleanQuery(searchQuery, 'onlyRecurring', 'onlyRecurring'),
      QueryManager.createBooleanQuery(searchQuery, 'limitedSubmission', 'limitedSubmission'),
      QueryManager.createStringQuery(searchQuery, 'lastUpdateDate'),
      QueryManager.createBooleanQuery(searchQuery, 'xfn', 'xfn'),
      QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
      QueryManager.createBooleanQuery(searchQuery, 'xApCn', 'xApCn'),
      QueryManager.createBooleanQuery(searchQuery, 'xApCz', 'xApCz'),
      QueryManager.createBooleanQuery(searchQuery, 'xOlCn', 'xOlCn'),
      QueryManager.createBooleanQuery(searchQuery, 'onlyInternal', 'onlyInternal'),
      QueryManager.createBooleanQuery(searchQuery, 'includeExpired', 'expired'),
      QueryManager.createBooleanQuery(searchQuery, 'forthcoming', 'forthcoming'),
      QueryManager.createBooleanQuery(searchQuery, 'unspecCit', 'unspecCit'),
      QueryManager.createBooleanQuery(searchQuery, 'unspecCountry', 'unspecCountry'),
    ]);
  }

  static generateFundersUrlQuery(searchQuery, queries) {
    return queries.concat([
      QueryManager.createAdvanceSearchQuery(searchQuery),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.funderCountries, 'funderCountries'),
      QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
    ]);
  }

  static generateAwardsUrlQuery(searchQuery, queries) {
    const doFormatSnakeCase = true;
    const dontFormatSnakeCase = false;
    const encodeValuesURI = true;
    return queries.concat([
      QueryManager.createAdvanceSearchQuery(searchQuery),
      QueryManager.createDisciplinesUrlQuery(searchQuery.researchAreas),
      QueryManager.createSubDisciplinesUrlQuery(searchQuery.researchAreas),
      QueryManager.createAmountUrlQuery(searchQuery.amount),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.fundingOrganizations, 'funder'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.startYear, 'startYear', 'value'),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.awardeeCountries, 'awardeeCountries', 'value', doFormatSnakeCase),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.grantTypes, 'grantTypes', 'value', doFormatSnakeCase),
      QueryManager.createUrlQueryFromSelectedItems(
        searchQuery.awardedInstitutions,
        'awardedInstitutions',
        'key',
        dontFormatSnakeCase,
        encodeValuesURI,
      ),
      QueryManager.createUrlQueryFromSelectedItems(
        searchQuery.awardedAuthors,
        'awardedAuthors',
        'key',
        dontFormatSnakeCase,
        encodeValuesURI,
      ),
      QueryManager.createUrlQueryFromSelectedItems(searchQuery.funderCountries, 'funderCountries'),
      QueryManager.createBooleanQuery(searchQuery, 'xRcCn', 'xRcCn'),
      QueryManager.createBooleanQuery(searchQuery, 'xfn', 'xfn'),
      QueryManager.createBooleanQuery(searchQuery, 'xfnCn', 'xfnCn'),
    ]);
  }

  static createStateFromUrlQuery(prevState, url, options = {}) {
    const urlParams = getUrlParameters(url);
    // these queries are used for all scopes
    let newSearchQuery = Object.assign(
      {},
      prevState.searchQuery,
      { currentPage: parseInt(urlParams.page || 1, 10) },
      QueryManager.createSelectedItemState(prevState.funderType, urlParams.funderType, {
        stateKey: 'funderType',
        valueKey: 'value',
        formatToSnakeCase: true,
      }),
    );
    if (options.scope === SEARCH_CATEGORIES.OPPORTUNITIES) {
      newSearchQuery = QueryManager.createOpportunitiesStateFromUrlQuery(prevState, newSearchQuery, options, urlParams);
    } else if (options.scope === SEARCH_CATEGORIES.FUNDERS) {
      newSearchQuery = QueryManager.createFundersStateFromUrlQuery(prevState, newSearchQuery, options, urlParams);
    } else if (options.scope === SEARCH_CATEGORIES.AWARDS) {
      QueryManager.createAwardsStateFromUrlQuery(prevState, newSearchQuery, options, urlParams);
    }
    return newSearchQuery;
  }

  static createOpportunitiesStateFromUrlQuery(prevState, newSearchQuery, options, urlParams) {
    let orderBy = OPPORTUNITIES_SORT_OPTIONS.find((item) => {
      return item.value === urlParams.orderBy;
    });

    orderBy = orderBy || OPPORTUNITIES_SORT_OPTIONS[0];

    return Object.assign(
      newSearchQuery,
      QueryManager.createAdvanceSearchQueryState(),
      QueryManager.createSelectedItemState(prevState.fundingTypes, urlParams.type, {
        stateKey: 'fundingTypes',
      }),
      QueryManager.createSelectedItemState(options.countries, urlParams.citizenship, {
        stateKey: 'countries',
      }),
      QueryManager.createSelectedItemState(options.countries, urlParams.applicantCountry, {
        stateKey: 'applicantCountry',
      }),
      QueryManager.createSelectedItemState(options.countries, urlParams.funderCountries, {
        stateKey: 'funderCountries',
      }),
      QueryManager.createSelectedAmountState(urlParams.amountMin, urlParams.amountMax),
      QueryManager.createSelectedItemState(prevState.fundingOrganizations, urlParams.funder, {
        stateKey: 'fundingOrganizations',
      }),
      QueryManager.createDueDatesState(urlParams.deadline, urlParams.minApplicationDueDate, urlParams.maxApplicationDueDate),
      QueryManager.createSelectedItemState(options.countries, urlParams[PARAM_NAMES_MAP.activityCountries], {
        stateKey: 'activityCountries',
      }),
      QueryManager.createResearchAreasState(urlParams.discipline, urlParams['sub-discipline'], prevState.researchAreas.items),
      QueryManager.createSelectedItemState(prevState.individualApplicantTypes, urlParams[PARAM_NAMES_MAP.individualApplicantTypes], {
        stateKey: 'individualApplicantTypes',
      }),
      QueryManager.createSelectedItemState(
        prevState.organizationalApplicantTypes,
        urlParams[PARAM_NAMES_MAP.organizationalApplicantTypes],
        {
          stateKey: 'organizationalApplicantTypes',
        },
      ),
      QueryManager.createSelectedItemState(prevState.degreeRequirements, urlParams[PARAM_NAMES_MAP.degreeRequirements], {
        stateKey: 'degreeRequirements',
      }),
      QueryManager.createSelectedItemState(prevState.restrictions, urlParams[PARAM_NAMES_MAP.restrictions], {
        stateKey: 'restrictions',
      }),
      { limitedSubmission: urlParams.limitedSubmission === 'true' },
      { lastUpdateDate: urlParams.lastUpdateDate },
      { onlyRecurring: urlParams.onlyRecurring === 'true' },
      { onlyInternal: urlParams.onlyInternal === 'true' },
      { includeExpired: urlParams.expired === 'true' },
      { forthcoming: urlParams.forthcoming === 'true' },
      { xfn: urlParams.xfn === 'true' },
      { xfnCn: urlParams.xfnCn === 'true' },
      { xApCn: urlParams.xApCn === 'true' },
      { xApCz: urlParams.xApCz === 'true' },
      { xOlCn: urlParams.xOlCn === 'true' },
      { unspecCit: urlParams.unspecCit === 'true' },
      { unspecCountry: urlParams.unspecCountry === 'true' },
      { savedSearchName: urlParams.savedSearchName || '' },
      { selectedSortOption: orderBy },
    );
  }

  static createFundersStateFromUrlQuery(prevState, newSearchQuery, options, urlParams) {
    let orderBy = FUNDERS_SORT_OPTIONS.find((item) => item.value === urlParams.orderBy);
    orderBy = orderBy || FUNDERS_SORT_OPTIONS[0];
    const obj = { keyword: '' };
    const { advanceSearch } = QueryManager.createAdvanceSearchQueryState();
    obj.keyword = advanceSearch.any.val;

    return Object.assign(
      newSearchQuery,
      { keyword: obj.keyword },
      { selectedSortOption: orderBy },
      QueryManager.createSelectedItemState(options.countries, urlParams.funderCountries, {
        stateKey: 'funderCountries',
      }),
      { xfnCn: urlParams.xfnCn === 'true' },
    );
  }

  static createAwardsStateFromUrlQuery(prevState, newSearchQuery, options, urlParams) {
    let orderBy = AWARDS_SORT_OPTIONS.find((item) => item.value === urlParams.orderBy);
    orderBy = orderBy || AWARDS_SORT_OPTIONS[0];

    return Object.assign(
      newSearchQuery,
      { selectedSortOption: orderBy },
      QueryManager.createAdvanceSearchQueryState(),
      QueryManager.createSelectedAmountState(urlParams.amountMin, urlParams.amountMax),
      QueryManager.createSelectedItemState(prevState.grantTypes, urlParams.grantTypes, {
        stateKey: 'grantTypes',
        valueKey: 'value',
        formatToSnakeCase: true,
      }),
      QueryManager.createSelectedItemState(prevState.startYear, urlParams.startYear, {
        stateKey: 'startYear',
        valueKey: 'value',
      }),
      QueryManager.createResearchAreasState(urlParams.discipline, urlParams['sub-discipline'], prevState.researchAreas.items),
      QueryManager.createSelectedItemState(options.countries, urlParams.awardeeCountries, {
        stateKey: 'awardeeCountries',
        valueKey: 'value',
        formatToSnakeCase: true,
      }),
      QueryManager.createSelectedItemState(options.countries, urlParams.funderCountries, {
        stateKey: 'funderCountries',
      }),
      QueryManager.createSelectedItemState(prevState.fundingOrganizations, urlParams.funder, {
        stateKey: 'fundingOrganizations',
      }),
      QueryManager.createSelectedItemState(prevState.awardedInstitutions, urlParams.awardedInstitutions, {
        stateKey: 'awardedInstitutions',
        allValuesKey: 'cache',
        encodeValue: true,
      }),
      QueryManager.createSelectedItemState(prevState.awardedAuthors, urlParams.awardedAuthors, {
        stateKey: 'awardedAuthors',
        allValuesKey: 'cache',
        encodeValue: true,
      }),
      { xRcCn: urlParams.xRcCn === 'true' },
      { xfn: urlParams.xfn === 'true' },
      { xfnCn: urlParams.xfnCn === 'true' },
    );
  }
}
