import { createSearchQueryObject } from './apiSearchQuery.ts';
import {
  createAdvancedSearchQueryObject,
  createAmountQueryObject,
  createBooleanQueryObject,
  createDisciplinesQueryObject,
  createListQueryObject,
  createPageQueryObject,
  createPageSizeQueryObject,
  createSortQueryObject,
  createStringQueryObject,
} from './commonSearchQuery.ts';

export function createStatusQueryObject(searchQuery: any, options: any) {
  let status = null;

  // if options.status is defined and its value is not null, set the status
  // otherwise, set always default status as active
  if (options.status !== undefined) {
    if (options.status !== null) {
      status = options.status;
    }
  } else {
    status = 'active';
  }

  if (status === null) {
    return {};
  }

  return {
    status: [status],
  };
}

export function createApplicationDeadlinesQueryObject(searchQuery: any) {
  const deadlines = searchQuery.applicationDeadline;

  if (!deadlines) {
    return {};
  }

  const queryObject: any = {};
  const deadlineDaysMinimum: number[] = [];
  const deadlineDaysMaximum: number[] = [];

  deadlines.selectedItems.forEach((item: any) => {
    if (item.min > -1) {
      deadlineDaysMinimum.push(item.min);
    }
    if (item.max > -1) {
      deadlineDaysMaximum.push(item.max);
    }
  });

  if (deadlineDaysMinimum.length > 0) {
    queryObject['deadlineDaysMinimum'] = deadlineDaysMinimum;
  }
  if (deadlineDaysMaximum.length > 0) {
    queryObject['deadlineDaysMaximum'] = deadlineDaysMaximum;
  }
  if (deadlines.startDate) {
    queryObject['minApplicationDueDate'] = deadlines.startDate;
  }
  if (deadlines.endDate) {
    queryObject['maxApplicationDueDate'] = deadlines.endDate;
  }

  return queryObject;
}

export function createLastUpdatedQueryObject(searchQuery: any, options: any) {
  const { savedSearchOptions } = options;

  if (savedSearchOptions && savedSearchOptions.lastVisitedDate) {
    return {
      lastVisitedDate: savedSearchOptions.lastVisitedDate,
    };
  }

  return {};
}

const createSearchQueryObjectFn = createSearchQueryObject(
  createPageSizeQueryObject,
  createPageQueryObject,
  createSortQueryObject,
  createStatusQueryObject,
  createAdvancedSearchQueryObject,
  createDisciplinesQueryObject,
  createListQueryObject('fundingOrganizations', 'subSponsor'),
  createListQueryObject('countries', 'citizenship'),
  createListQueryObject('applicantCountry', 'applicantCountry'),
  createListQueryObject('funderCountries', 'funderCountry'),
  createListQueryObject('funderType', 'funderTypeGroupId', 'types'),
  createListQueryObject('individualApplicantTypes', 'iat'),
  createListQueryObject('organizationalApplicantTypes', 'oat'),
  createListQueryObject('degreeRequirements', 'dr'),
  createListQueryObject('restrictions', 'rst'),
  createListQueryObject('activityCountries', 'ol'),
  createListQueryObject('fundingTypes', 'types'),
  createApplicationDeadlinesQueryObject,
  createAmountQueryObject,
  createBooleanQueryObject('onlyRecurring', 'onlyRecurring'),
  createBooleanQueryObject('limitedSubmission', 'limitedSubmission'),
  createBooleanQueryObject('xfn', 'xfn'),
  createBooleanQueryObject('xfnCn', 'xfnCn'),
  createBooleanQueryObject('xApCn', 'xApCn'),
  createBooleanQueryObject('xApCz', 'xApCz'),
  createBooleanQueryObject('xOlCn', 'xOlCn'),
  createBooleanQueryObject('onlyInternal', 'onlyInternal'),
  createBooleanQueryObject('includeExpired', 'includeExpired'),
  createBooleanQueryObject('forthcoming', 'forthcoming'),
  createLastUpdatedQueryObject,
  createStringQueryObject('lastUpdateDate'),
  createBooleanQueryObject('unspecCit', 'unspecCit'),
  createBooleanQueryObject('unspecCountry', 'unspecCountry'),
);

export function createOpportunitySearchQueryObject(searchQuery: any, options: any) {
  return createSearchQueryObjectFn(searchQuery, options);
}
