import { createAction, createReducer } from 'redux-act';

import {
  updateSearchQueryReducer,
  setIsLoadingResults,
  selectItem,
  deselectItems,
  paginateSearch,
  selectAllPage,
  setSelectedItems,
  deselectAll,
  researchAreasReducer,
  setMetadataIsFetched,
  pushSearchUrlToHistory,
  acceptFundingTypesReducer,
  acceptFundingOrganizationsReducer,
  acceptOpportunitiesFunderType,
  setInitialCountMap,
  isFileExporting,
  exportFileFailed,
  updateOpportunitiesWithNotes,
  setSelectAllContext,
  setIndividualApplicantTypesReducer,
  setOrganizationalApplicantTypesReducer,
  setDegreeRequirementsReducer,
  setRestrictionsReducer,
  setLoadingSimilarOpportunities,
  setSimilarOpportunities,
  acceptFundingOrganizationsCountsReducer,
  acceptFundingOrganizationsSearchResponseReducer,
} from './common.js';
import { OPPORTUNITIES_SORT_OPTIONS } from '@fi/constants';
import { FacetUtils } from '@fi/util/FacetUtils';
import { opportunityNoteModalActions } from './opportunityNoteModal.js';
import { selectAllActions } from './selectAll.js';

/* Actions */
/**
 * @type {Object.<string, (...args: any) => EmptyActionCreator<null, null>>}
 */
export const opportunitiesActions = {
  runSearch: createAction('run search'),
  fetchOpportunities: createAction('fetch opportunities'),
  fetchAggregations: createAction('fetch aggregations'),
  fetchSimilarOpportunities: createAction('fetch similar opportunities'),
  setLoadingSimilarOpportunities: createAction('set loading similar opportunities'),
  setSimilarOpportunities: createAction('set similar opportunities'),
  setOpportunityDetails: createAction('set details'),
  setMetadataIsFetched: createAction('set metadata is fetched'),
  setIsLoadingResults: createAction('set is loading results'),
  fetchMetadata: createAction('fetch metadata'),
  loadOpportunities: createAction('Load opportunities'),
  loadAggregations: createAction('Load opportunities'),
  loadFundingTypes: createAction('Load funding types'),
  loadClassifications: createAction('Load classifications'),
  updateSearch: createAction('Update opportunities search state'),
  loadFundingOrganizations: createAction('Load funding organizations'),
  loadFundingOrganizationsCounts: createAction('Load funding organizations counts'),
  loadFundingOrganizationsSearchResponse: createAction('Load funding organizations from search response'),
  loadFunderType: createAction('Load Sub Sponsors'),
  selectItem: createAction('Select item'),
  deselectItems: createAction('Deselect items'),
  deselectAll: createAction('Deselect all opportunities'),
  selectAllPage: createAction('Select all page'),
  selectAllSearchResult: createAction('Select all search results'),
  setSelectedItems: createAction('set selected Items'),
  paginateSearch: createAction('Paginating search results'),
  loadResearchAreas: createAction('Load research areas'),
  orderResearchAreas: createAction('Order research areas'),
  loadGrantTypes: createAction('load opportunity grant types'),
  setSavedSearchOptions: createAction('set saved search options'),
  resetSavedSearchOptions: createAction('reset saved search options'),
  pushSearchUrlToHistory: createAction('push search url to history'),
  setOpportunityIsTracked: createAction('set opportunity isTracked'),
  setOpportunityIsUnTracked: createAction('set opportunity isTracked to false'),
  setSelectAllContext: createAction('Set select all context'),
  setInitialCountMap: createAction('set initial count map'),
  exportFile: createAction('exporting file', (payload) => payload),
  exportFileFailed: createAction('exporting file failed'),
  isFileExporting: createAction('exporting file in progress'),
  setIndividualApplicantTypes: createAction('set individual applicant types'),
  setOrganizationalApplicantTypes: createAction('set organizational applicant types'),
  setDegreeRequirements: createAction('set degree requirements'),
  setRestrictions: createAction('set applicant restrictions'),

  // Hacky solution to keep opportunity separate from opportunity details.
  // It makes more sense to use general notes state to handle all notes data.
  // This should be stored as part of notes listing, so it could be reused from there.
  setOpportunityNote: createAction('set opportunity note data'),
  setApiQuery: createAction('set api search query'),
  setIsInvalidQuery: createAction('set invalid query'),
};

const SEARCH_QUERY_DEFAULT = {
  keyword: '',
  currentPage: 1,
  sortOptions: OPPORTUNITIES_SORT_OPTIONS,
  selectedSortOption: OPPORTUNITIES_SORT_OPTIONS[0],
  researchAreas: {
    parentMap: {},
    childMap: {},
  },
  fundingTypes: {
    selectedItems: [],
  },
  countries: {
    selectedItems: [],
  },
  funderCountries: {
    selectedItems: [],
  },
  funderType: {
    selectedItems: [],
  },
  amount: {
    min: 0,
    max: 0,
    includeUnspecifiedAmounts: false,
  },
  limitedSubmission: false,
  onlyInternal: false,
  lastUpdateDate: null, // new opportunities since a specified date
  includeExpired: false,
  forthcoming: false,
  applicationDeadline: {
    selectedItems: [],
  },
  fundingOrganizations: {
    selectedItems: [],
  },
  applicantCountry: {
    selectedItems: [],
  },
  activityCountries: {
    selectedItems: [],
  },
  individualApplicantTypes: {
    selectedItems: [],
  },
  organizationalApplicantTypes: {
    selectedItems: [],
  },
  degreeRequirements: {
    selectedItems: [],
  },
  restrictions: {
    selectedItems: [],
  },
  onlyRecurring: false,
  xfn: false,
  xfnCn: false,
  xApCn: false,
  xApCz: false,
  xOlCn: false,
  savedSearchName: '',
  advanceSearch: {},
  unspecCit: false,
  unspecCountry: false,
};

/* Reducers */

function loadOpportunitiesReducer(state, payload) {
  const results = payload.hits.hits.map((item) => {
    const opportunity = item._source;
    opportunity.score = item._score;
    opportunity.missingWords = item.missingPhrases;
    return opportunity;
  });

  return Object.assign({}, state, {
    isLoading: false,
    results,
    totalResults: payload.hits.total.value,
  });
}

const loadAggregationsReducer = (state, payload) => {
  const researchAreas = FacetUtils.getResearchAreaCounts(payload, state.researchAreas);
  const fundingTypes = FacetUtils.getAggregationItemCounts(payload.fundingTypes, state.fundingTypes);
  const individualApplicantTypes = FacetUtils.getAggregationItemCounts(payload.individualApplicantTypes, state.individualApplicantTypes);
  const organizationalApplicantTypes = FacetUtils.getAggregationItemCounts(
    payload.organizationalApplicantTypes,
    state.organizationalApplicantTypes,
  );
  const degreeRequirements = FacetUtils.getAggregationItemCounts(payload.degreeRequirements, state.degreeRequirements);
  const restrictions = FacetUtils.getAggregationItemCounts(payload.restrictions, state.restrictions);
  const funderType = FacetUtils.getAggregationItemCounts(payload.funderTypes, state.funderType);

  return Object.assign({}, state, {
    researchAreas,
    fundingTypes,
    funderType,
    individualApplicantTypes,
    organizationalApplicantTypes,
    degreeRequirements,
    restrictions,
  });
};

const setOpportunityDetails = (state, opportunityDetails) => ({
  ...state,
  opportunityDetails,
});

const acceptGrantTypes = (state, grantTypes) => ({
  ...state,
  grantTypes,
});

const setIsTrackedOnOppsList = (oppsList, ids, value) => {
  if (oppsList) {
    return oppsList.map((item) => {
      if (ids.includes(item.id)) {
        return {
          ...item,
          isTracked: value,
        };
      }
      return item;
    });
  }

  return [];
};

const updateOpportunityIsTracked = (state, ids, value) => {
  const results = setIsTrackedOnOppsList(state.results, ids, value);
  const updatedSimilarOpportunitiesItems = state?.similarOpportunities?.items
    ? setIsTrackedOnOppsList(state.similarOpportunities.items, ids, value)
    : [];

  return {
    ...state,
    results,
    similarOpportunities: {
      ...state.similarOpportunities,
      items: updatedSimilarOpportunitiesItems,
    },
  };
};

const setOpportunityIsTracked = (state, ids) => {
  return updateOpportunityIsTracked(state, ids, true);
};

const setOpportunityIsUnTracked = (state, ids) => {
  return updateOpportunityIsTracked(state, ids, false);
};

const setOpportunityNote = (state, payload) => {
  const { opportunityId, opportunityNote } = payload;

  return {
    ...state,
    opportunityNotes: {
      ...state.opportunityNotes,
      [opportunityId]: opportunityNote,
    },
  };
};

const setApiQuery = (state, apiQuery) => ({
  ...state,
  apiQuery,
});

const setIsInvalidQuery = (state, isInvalidQuery) => ({
  ...state,
  isInvalidQuery,
});

export const opportunitiesReducer = createReducer(
  {
    [opportunitiesActions.setMetadataIsFetched]: setMetadataIsFetched,
    [opportunitiesActions.setIsLoadingResults]: setIsLoadingResults,
    [opportunitiesActions.setOpportunityDetails]: setOpportunityDetails,
    [opportunitiesActions.loadOpportunities]: loadOpportunitiesReducer,
    [opportunitiesActions.loadAggregations]: loadAggregationsReducer,
    [opportunitiesActions.loadFundingTypes]: acceptFundingTypesReducer,
    [opportunitiesActions.updateSearch]: updateSearchQueryReducer,
    [opportunitiesActions.selectItem]: selectItem,
    [opportunitiesActions.deselectItems]: deselectItems,
    [opportunitiesActions.deselectAll]: deselectAll,
    [opportunitiesActions.selectAllPage]: selectAllPage,
    [opportunitiesActions.setSelectedItems]: setSelectedItems,
    [opportunitiesActions.paginateSearch]: paginateSearch,
    [opportunitiesActions.loadFundingOrganizations]: acceptFundingOrganizationsReducer,
    [opportunitiesActions.loadFundingOrganizationsCounts]: acceptFundingOrganizationsCountsReducer,
    [opportunitiesActions.loadFundingOrganizationsSearchResponse]: acceptFundingOrganizationsSearchResponseReducer,
    [opportunitiesActions.loadFunderType]: acceptOpportunitiesFunderType,
    [opportunitiesActions.loadResearchAreas]: researchAreasReducer,
    [opportunitiesActions.loadGrantTypes]: acceptGrantTypes,
    [opportunitiesActions.pushSearchUrlToHistory]: pushSearchUrlToHistory,
    [opportunitiesActions.setOpportunityIsTracked]: setOpportunityIsTracked,
    [opportunitiesActions.setOpportunityIsUnTracked]: setOpportunityIsUnTracked,
    [opportunitiesActions.setSelectAllContext]: setSelectAllContext,
    [opportunitiesActions.setInitialCountMap]: setInitialCountMap,
    [opportunitiesActions.isFileExporting]: isFileExporting,
    [opportunitiesActions.exportFileFailed]: exportFileFailed,
    [opportunitiesActions.setOpportunityNote]: setOpportunityNote,
    [opportunitiesActions.setIndividualApplicantTypes]: setIndividualApplicantTypesReducer,
    [opportunitiesActions.setOrganizationalApplicantTypes]: setOrganizationalApplicantTypesReducer,
    [opportunitiesActions.setDegreeRequirements]: setDegreeRequirementsReducer,
    [opportunitiesActions.setRestrictions]: setRestrictionsReducer,
    [opportunityNoteModalActions.updateOpportunitiesWithNotes]: updateOpportunitiesWithNotes,
    [selectAllActions.deselectAllOpportunities]: deselectAll,
    [opportunitiesActions.setLoadingSimilarOpportunities]: setLoadingSimilarOpportunities,
    [opportunitiesActions.setSimilarOpportunities]: setSimilarOpportunities,
    [opportunitiesActions.setApiQuery]: setApiQuery,
    [opportunitiesActions.setIsInvalidQuery]: setIsInvalidQuery,
  },
  {
    searchHistory: '', // latest search query
    searchQuery: SEARCH_QUERY_DEFAULT,
    apiQuery: {},
    fundingTypes: {
      items: [],
      countMap: {},
    },
    researchAreas: {
      items: [],
      countMap: {},
      initialCountMap: {},
    },
    funderType: {
      items: [],
      countMap: {},
    },
    results: [],
    selectedItems: {}, // the model is object: [itemId]: 'itemId',
    totalResults: 0,
    isLoadingResults: true,
    isInvalidQuery: false,
    isMetadataFetched: false,
    fundingOrganizations: {
      items: [],
      countMap: {},
      itemsMap: {},
    },
    opportunityDetails: {},
    opportunityNotes: {},
    funderTypes: {},
    savedSearchOptions: {},
    selectAllContext: 'all',
    exportFailed: null,
  },
);
